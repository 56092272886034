<template>
  <v-layout row wrap justify-center align-start>
    <v-flex xs12 lg12 md12 class="mb-3">
      <v-card>
        <titleCard
          title="Listado de solicitudes de insumos"
          subtitle="Ingrese o revise el estado de sus solicitudes"
        ></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0 order-table"
                color="secondary"
                :disable-initial-sort="true"
                no-data-text="No solicitudes ingresadas"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-left">{{ props.item.id }}</td>
                    <td class="text-xs-left">{{ props.item.date_in }}</td>
                    <td class="text-xs-center">
                      <span v-if="props.item.status === 0"> Pendiente </span>
                    </td>
                    <td class="text-xs-right">
                      <v-icon
                        v-if="user.role.id === 1 || user.role.id === 2"
                        small
                        class="mr-2"
                        @click="editItem(props.item)"
                      >
                        far fa-edit
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogItem" max-width="700px">
      <v-card>
        <app-title-dialog :title="'Solicitar insumos'" :close-action="() => (dialogItem = false)" close>
        </app-title-dialog>
        <v-container class="pa-3" grid-list-lg>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                v-model="form.date_shipping"
                type="date"
                label="Fecha de retiro"
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field v-model="quantity" label="Cantidad" type="number" hide-details></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field v-model="name" label="Insumos" type="text" hide-details></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-btn class="mt-3" color="accent" depressed block @click="addInsumo"> Agregar </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-data-table
                :headers="[
                  { text: 'Cantidad', align: 'left', sortable: false },
                  { text: 'Nombre', align: 'left', sortable: false },
                  { text: '', align: 'right', sortable: false }
                ]"
                :items="form.insumos"
                no-data-text="Ingrese un insumo..."
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs-left">
                    <v-edit-dialog :return-value.sync="props.item.quantity" lazy>
                      {{ props.item.quantity }}
                      <template v-slot:input>
                        <v-text-field v-model="props.item.quantity" label="Cantidad" single-line counter></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td class="text-xs-left">
                    <v-edit-dialog :return-value.sync="props.item.name" lazy>
                      {{ props.item.name }}
                      <template v-slot:input>
                        <v-text-field v-model="props.item.name" label="Nombre" single-line counter></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td class="text-xs-right">
                    <v-icon small class="mr-2" @click="deleteItem(props.item)">
                      delete
                    </v-icon>
                  </td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="greyback" flat @click.native="dialogItem = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="saveForm"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn dark fixed bottom right color="accent" @click="editItem({})">
      <v-icon left>add</v-icon>
      Solicitar insumos
    </v-btn>
  </v-layout>
</template>

<script>
import { GET_ORDERS } from '../../config'
import titleCard from '../useful/titleCard.vue'
import appStatus from '../useful/status.vue'
import appTitleDialog from '../useful/titleDialog.vue'
import FileReader from '../useful/FileReader.vue'

export default {
  name: 'StoreDealers',
  components: {
    titleCard,
    appStatus,
    appTitleDialog,
    FileReader
  },
  data: () => ({
    closePhoto: '',
    dialogItem: false,
    headers: [
      { text: 'ID', align: 'left', sortable: false },
      { text: 'Fecha', align: 'left', sortable: false },
      { text: 'Estado', align: 'center', sortable: false },
      { text: '', align: 'right', sortable: false }
    ],
    items: [],
    form: {
      insumos: [],
      date_in: null
    },
    fileUpload: null,
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    quantity: 1,
    name: null
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    console.log('mounted')
    this.getItems()
  },
  methods: {
    getFile(file) {
      this.fileUpload = file
    },
    changeStatus(item) {
      this.item = item
      this.item.status = !this.item.status
      this.saveItem()
    },
    editItem(item) {
      if (item) {
        this.form = item
      }
      this.dialogItem = true
    },
    addInsumo() {
      if (this.name !== '') {
        const item = {
          name: this.name,
          quantity: this.quantity
        }
        this.form.insumos.push(item)
        this.quantity = 1
        this.name = null
      }
    },
    getItems() {
      this.$http({
        method: 'post',
        url: GET_ORDERS,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: this.$qs.stringify({
          p: 'getSolicitudesInsumos'
        })
      })
        .then(res => {
          this.items = res.data.json
        })
        .catch(() => {
          this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
        })
    },
    saveForm() {
      if (this.form.date_in !== null && this.form.insumos.length > 0) {
        this.$http({
          method: 'post',
          url: GET_ORDERS,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: this.$qs.stringify({
            p: 'saveFormInsumos',
            form: this.form
          })
        })
          .then(() => {
            this.dialogItem = false
            this.form.date_in = null
            this.form.insumos = []
          })
          .catch(() => {
            this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
          })
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: 'Debe completar todos los campos' })
      }
    },
    deleteItem(index) {
      for (let i = 0; i < this.form.insumos.length; i += 1) {
        if (this.form.insumos[i] === index) {
          this.form.insumos.splice(i, 1)
          i -= 1
        }
      }
    }
  }
}
</script>

<style></style>
